<template>
<button class="btn p-relative"
          :class="{loading}"
		  @click="target">
    <slot></slot>

    <input type="file" ref="file" @change="onChange" hidden :accept="type ? type.join(','): ''"/>
</button>
</template>

<script>
  export default {
    props: {
      ratio: String,
      type: [String, Array],
	  maxSize: Number,
	  loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        loaded: false,
        files: []
      }
    },
    methods: {
      clear () {
        this.$refs['file'].value = [];
        this.files = [];
      },

      onChange () {
        let files = this.$refs['file'].files;

        if (files){

          Object.keys(files).map(i => { this.addFile(files[i]) });

          this.$emit('input', this.files);
          this.$emit('change', this.files[0]);
        }

        this.clear();
      },

      target (event) {
        this.$refs['file'].click();
        this.$emit('click', event);
      },

      addFile (file) {

        if(this.type === undefined) {
          this.$emit('file', file)
          this.files.push(file);
        }
        else if(this.type === file.type || this.type.includes(file.type)) {

          this.$emit('file', file)
          this.files.push(file);
        }
      }
    }
  }
</script>